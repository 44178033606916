<template>
  <v-footer 
    app
    class="font-weight-regular caption grey--text"
    padless
    color="grey lighten-3"
  >
    <!-- <v-spacer></v-spacer> -->
    <v-col      
      cols="12"
      class="text-center"
    >
      &copy; {{ new Date().getFullYear() }} Todos Los Derechos Reservados. Desarrollado por <a href="https://www.pohlbros.com" target="_blank">Pohlbros</a>. Contacto: <a href="mailto:timii@pohlbros.com">timii@pohlbros.com</a>.
    </v-col>
  </v-footer>
</template>

<script>
export default {
  name: "TheFooter",

  data: () => ({
  })
};
</script>
