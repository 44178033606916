<template>
  <div id="app">
    <v-app id="inspire">

      <router-view/>

      <the-footer/>

    </v-app>
  </div>
</template>

<script>
import TheFooter from '@/components/TheFooter'
import { mapActions } from 'vuex'

export default {
  name: "App",
  components: {
    TheFooter
  },
  data: () => ({
  }),
  mounted () {
  },
  methods: {
    /* ...mapActions('auth', ['CHECK_USER_SESSION']), */
  }
};
</script>