import { extend, localize } from "vee-validate"
import { required, email, min, max } from "vee-validate/dist/rules"

extend("required", {
  ...required,
  message: "El campo es obligatorio"
});
extend('email', {
  ...email,
  message: "Formato de email no válido"
});
extend('min', {
  ...min,
  message: "El campo debe tener almenos {length} caracteres"
});
extend('max', {
  ...max,
  message: "El campo debe tener como máximo {length} caracteres"
});
extend('password', {
  params: ['target'],
  validate(value, { target }) {
    return value === target;
  },
  message: 'La confirmación de la contraseña no coincide'
});

localize("es");


