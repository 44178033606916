import Vue from "vue";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";
import '@mdi/font/css/materialdesignicons.css';
import vuetify from "@/plugins/vuetify.js";

import "@/plugins/vee-validate";

/* import "@/helpers/filters"; */
import colors from 'vuetify/lib/util/colors'

Vue.config.productionTip = false

import AxiosPlugin from "@/plugins/axios.js";
Vue.use(AxiosPlugin)

new Vue({
  icons: {
    iconfont: 'mdi', // default - only for display purposes
  },
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
