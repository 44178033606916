import axios from "@/plugins/axios-config";
import router from "@/router";

const state = {
  isAuth: false,
  userData: null
}

// GETTERS
const getters = {
  isAuthenticated: ( state ) => state.isAuth,
  getUserData: ( state ) => state.userData,
}

// MUTATIONS
const mutations = {
  SET_USER_DATA (state, payload) { state.userData = payload },
  SET_IS_AUTHENTICATED (state, payload) { state.isAuth = payload }
}

// ACTIONS
const actions = {
  LOGIN ( context, userData ) {   
    // No interceptar el error para mostrarlo en el componente Login
    axios.interceptors.response.eject(axios.responseInterceptor);

    return new Promise((resolve, reject) => {
      // Petición Axios a Base de datos
      axios.post('usr_usuarios/login', { user: userData.name, password: userData.password } )
      .then( (respuesta) =>{  
        //console.log(respuesta.data)
               
        if( this.checkResponseData(respuesta.data) ){
          // Guardar datos de usuario en el store
          context.commit('SET_USER_DATA', respuesta.data.data)
          context.commit('SET_IS_AUTHENTICATED', true)
          resolve()

          // Redirect to dashboard
          router.push({ name: "search" })
        }else{
          //context.commit('messageHandler/SET_MESSAGE', { status: 400, statusText: 'Formato de respuesta no válido!' }, { root: true } )
          context.commit('SET_IS_AUTHENTICATED', false)
          reject({ status: 400, statusText: 'Formato de respuesta no válido!' })
        }  
      })  
      .catch( (error) =>{ 
        //context.commit('messageHandler/SET_MESSAGE', { status: error.response.status, statusText: error.response.statusText}, { root: true }) 
        context.commit('SET_IS_AUTHENTICATED', false)
        setTimeout( () =>{
          reject({ status: error.response.status, statusText: error.response.data.message})
        }, 500);
        
      })   
    });      
  },
  LOGOUT ( context, userData) {
    axios.get('usr_usuarios/logout')
    .then( (respuesta) =>{  
      //console.log(respuesta.data)
      
      if( this.checkResponseData(respuesta.data) ){
        // Guardar datos de usuario en el store         
        context.commit('SET_USER_DATA', {usr_role: null, usr_name: null})
        context.commit('SET_IS_AUTHENTICATED', false)

        router.push({ name: "login" })        

        context.commit('messageHandler/SET_MESSAGE', { status: 200, statusText: 'Sesión cerrada con éxito!' }, { root: true } )
      }else{
        context.commit('messageHandler/SET_MESSAGE', { status: 400, statusText: 'Formato de respuesta no válido!' }, { root: true } )
        context.commit('SET_IS_AUTHENTICATED', false)
      }  
    })  
    .catch( (error) =>{ 
      // Mostrar error: La sesión ha expirado o ha habido error
      //console.log(error)
      context.commit('messageHandler/SET_MESSAGE', { status: error.response.status, statusText: error.response.statusText}, { root: true })      
      context.commit('SET_IS_AUTHENTICATED', false)
    })
  },
  CHECK_USER_SESSION( context ){
    // Petición Axios a Base de datos
    return new Promise((resolve, reject) => {
      axios.get('usr_usuarios/checksession')
      .then( (respuesta) =>{  
        //console.log(respuesta.data)
        
        if( this.checkResponseData(respuesta.data) ){
          if( respuesta.data.data.hasOwnProperty('usr_role') ){
            //console.log('SI')
            // Guardar datos de usuario en el store
            context.commit('SET_USER_DATA', respuesta.data.data)
            context.commit('SET_IS_AUTHENTICATED', true)
            resolve()
          }else{
            //console.log('No')
            context.commit('messageHandler/SET_MESSAGE', { status: 400, statusText: 'Formato de respuesta no válido!' }, { root: true } )
            context.commit('SET_IS_AUTHENTICATED', false)
            reject()
          }
        }else{
          context.commit('messageHandler/SET_MESSAGE', { status: 400, statusText: 'Formato de respuesta no válido!' }, { root: true } )
          context.commit('SET_IS_AUTHENTICATED', false)
          reject()
        }  
      })  
      .catch( (error) =>{ 
        // Mostrar error: La sesión ha expirado o ha habido error
        //console.log(error)
        context.commit('messageHandler/SET_MESSAGE', { status: error.response.status, statusText: error.response.statusText}, { root: true })      
        context.commit('SET_IS_AUTHENTICATED', false)
        reject(error)
      })
    }); 
  } 
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions 
}