const preMetaTitle = process.env.VUE_APP_TITLE + ' - '

export const routes = [
  {
    path: "/login",
    name: "login",
    meta: { breadcrumb: [ { title: preMetaTitle + "Login", routeName: "" } ] } ,
    component: () => import( "@/views/Login.vue")
  },
  {
    path: "/dbm",
    name: "database-management",
    meta: { breadcrumb: [ { title: "", routeName: "" } ], requiresAuth: true },
    component: () => import( "@/views/ControlPanel.vue"),

    children: [
      {
        path: "search",
        name: "search",
        meta: { breadcrumb: [ { title: preMetaTitle + "Buscador", routeName: "" } ], roles: ['admin', 'editor', 'suscriber'] },
        component: () => import( "@/views/Search.vue")
      },
      {
        path: "institution-proposals",
        name: "institution-proposals",
        meta: { breadcrumb: [ { title: preMetaTitle + "Instituciones propuestas", routeName: "" } ], roles: ['admin', 'editor'] },
        component: () => import( "@/views/InstitutionProposals.vue")
      },
      {
        path: "institution-management",
        name: "institution-management",
        meta: { breadcrumb: [ { title: preMetaTitle + "Instituciones aprobadas", routeName: "" } ], roles: ['admin', 'editor'] },
        component: () => import( "@/views/InstitutionManagement.vue")
      },
      {
        path: "user-management",
        name: "user-management",
        meta: { breadcrumb: [ { title: preMetaTitle + "Gestión Usuarios", routeName: "" } ], roles: ['admin'] },
        component: () => import( "@/views/UserManagement.vue")
      },
      {
        path: "help",
        name: "help",
        meta: { breadcrumb: [ { title: preMetaTitle + "Ayuda", routeName: "" } ], roles: ['admin', 'editor', 'suscriber'] },
        component: () => import( "@/views/Help.vue")
      },
      {
        path: "admin-tools",
        name: "admin-tools",
        meta: { breadcrumb: [ { title: preMetaTitle + "Admin Tools", routeName: "" } ], roles: ['admin'] },
        component: () => import( "@/views/AdminTools.vue")
      },
    ]
  },
  { path: "*", redirect: { name: "login" } },
];