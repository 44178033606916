//import Vue from "vue"

const state = {
  globalMessage: [],
}

// GETTERS
const getters = {
  globalMessage: (state) => {
    return state.globalMessage
  }
}

// MUTATIONS
const mutations = {
  SET_MESSAGE (state, message) {
    //Vue.set(state.globalMessage, "", message)
    state.globalMessage.push(message)
  },
  DELETE_MESSAGE (state) {
    //Vue.set(state.globalMessage, "", message)
    //console.log(state.globalMessage)
    state.globalMessage.splice(0,1)
    //console.log("deleteMessage")
    //console.log(state.globalMessage)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations
}