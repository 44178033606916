import Vue from "vue";
import store from "@/store";
import { sync } from 'vuex-router-sync'
import VueRouter from "vue-router";

import { routes } from '@/router/routes';

Vue.use(VueRouter);

const router = new VueRouter({
  routes
});

sync(store, router);

router.beforeEach((to, from, next) => {
  // Set Page Title
  let title
  if ( to.meta.hasOwnProperty('breadcrumb') ) {
    for(let i=0; i<to.meta.breadcrumb.length; i++){
      if( i > 0){
        title = title + ' / ' + to.meta.breadcrumb[i].title;
      }else{
        title = to.meta.breadcrumb[i].title;
      }
    }
    document.title = title;
  }

  // Check authorization
  // make sure to always call next()!
  if (to.matched.some(routes => routes.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.

    if( store.getters['auth/getUserData'] == null ){
      // Page was reloaded => Check session
      store.dispatch('auth/CHECK_USER_SESSION')
      .then( () =>{  
        if( store.getters['auth/isAuthenticated'] ){
          if( to.meta.roles.includes( store.getters['auth/getUserData'].usr_role ) ){ 
            next()            
          }else{
            next({ name: 'search' })
          }
        }else{
          next({ name: 'login' })
        } 
      })
      .catch( (error) => { 
        next({ name: 'login' })
      })
    }else{
      if( store.getters['auth/isAuthenticated'] && to.meta.roles.includes( store.getters['auth/getUserData'].usr_role  ) ){
        next()
      }else if(store.getters['auth/isAuthenticated']){
        next({ name: 'search' })
      }else{
        next({ name: 'login' })
      }
    }

    /* console.log(store.getters['auth/isAuthenticated'])
    console.log(store.getters['auth/getUserData'])

    console.log('mounted')
    this.$store.dispatch('auth/CHECK_USER_SESSION');
    
    if( store.getters['auth/isAuthenticated'] && to.meta.roles.includes( store.getters['auth/getUserData'].role  ) ){
      next()
    }else{
      console.log('to dashboard')
      //next({ name: 'dashboard' })
    } */
  } else {
    next()     
  }
})

export default router
