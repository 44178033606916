import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: colors.teal,
        secondary: colors.teal.darken1,
        /* primary: colors.teal.accent4,
        secondary: colors.teal,
        accent: colors.shades.black,
        error: colors.red.accent3, */
      },
      dark: {
        primary: colors.blue.lighten3,
      },
    },
  },
})
