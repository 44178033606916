import Vue from 'vue'
import Vuex from 'vuex'
import axios from "axios"

import auth from "@/store/modules/auth"
import messageHandler from "@/store/modules/messageHandler"

import { version } from '@/../package'

function checkResponseData(data){
	if ( isObject(data.data) ) {    
		return true;   
	}else{
		return false;
	} 
}

function isObject(val) {
	if (val === null) { return false;}
	return ( (typeof val === 'function') || (typeof val === 'object') );
}

const checkResponseDataPlugin = store =>{
  store.checkResponseData = function (data){
    if ( isObject(data.data) ) {    
      return true;   
    }else{
      return false;
    } 
  }
}

Vue.use(Vuex)

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== "production",
  modules: {    
    auth: auth,
    messageHandler: messageHandler,    
  },
  plugins: [checkResponseDataPlugin],
  state: {
    apiUrl: process.env.VUE_APP_API_URL,
  	baseUrl: process.env.VUE_APP_BASE_URL,
    appVersion: version,
    filesUrl: 'files/',
    institutions: [],
  },
  getters:{
    getApiUrl: ( state ) => state.apiUrl,
    getBaseUrl: ( state ) => state.baseUrl,
    getAppVersion: ( state ) => state.appVersion,
    getFilesUrl: ( state ) => state.filesUrl,
    getInstitutions: ( state ) => state.institutions,    
  },
  mutations: {
    SET_INSTITUTIONS: ( state, value ) => state.institutions = value,
  },
  actions: {
    loadInstitutions: ( context ) => { 
      return new Promise((resolve, reject) => {
        context.commit('SET_INSTITUTIONS', []);
        axios.get(process.env.VUE_APP_API_URL + 'ins_instituciones?fields=tip_tipos,not_notificaciones&order=-ins_insert_date')
        .then( (respuesta) =>{  
          //console.log(respuesta.data)
          if( checkResponseData(respuesta.data) ){         
            context.commit('SET_INSTITUTIONS', respuesta.data.data)
          }else{
            context.commit('messageHandler/SET_MESSAGE', { status: 400, statusText: respuesta.data } )
          }  
          resolve()
        })  
        .catch( (error) =>{ 
          //console.log(error)
          let  message = '';
          if (error.response.data.hasOwnProperty('message')) {
            message = error.response.data.message;
          }else{
            message = error
          }
          context.commit('messageHandler/SET_MESSAGE', { status: 400, statusText: message } )	
          resolve()
        })
      })    
    },
  } 
})
