import axios from "axios";
import store from "@/store";

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL
})

instance.responseInterceptor = instance.interceptors.response.use( (response) => {
  // Do something with response data
  //store.commit("auth/setUserData", response.data)
  //console.log(response.data.data)
  //console.log(typeof response.data.data)
  if( response.data.res_num == 0 ){
    store.commit("messageHandler/SET_MESSAGE", { status: 204, statusText: "No se han encontrado resultados." } )
    return Promise.reject({ status: 204, statusText: "No se han encontrado resultados." });
  }else{
    return response;
  }
}, function (error) {
  //console.log(error.response)
  //store.commit("messageHandler/setMessage", { status: error.response.status, statusText: error.response.statusText} )
  //store.commit("messageHandler/SET_MESSAGE", { status: error.data.status.code, statusText: error.response.data.status.message} )
  return Promise.reject(error);
});

export default instance